import { APP_INITIALIZER, inject } from '@angular/core';
import { ShipmentRouteDto, ShipmentRouteEntityName } from '@nest/domain-entities-metadata-dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit-entity-presentation-ui';
import { autoPathsFor } from '@nest/utils-shared';

const paths = autoPathsFor<ShipmentRouteDto>();

export const ShipmentRouteDefaultElements = fieldsToElements(paths(['startAt', 'driver.name', 'vehicle.name', 'documentNumber']));

export function provideShipmentRouteEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				const entityMetadata = inject(EntityMetadataService);

				return () => {
					entityMetadata.registerEntity({
						entityName: ShipmentRouteEntityName,
						defaultElements: ShipmentRouteDefaultElements
					});
				};
			}
		}
	];
}
