import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { PropertyToJson2 } from '@nest/common-core-shared';
import { Entity } from '@nest/uikit-entity-presentation-ui';

@Component({
	standalone: true,
	template: `{{ propertyMetadata()?.enumValues[value()] }}`,
	styles: `
		:host {
			@apply inline-block p-1 px-2 rounded-md text-ellipsis overflow-hidden w-full text-center text-nowrap;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaleOrderStateColorEntityPathRendererComponent<E extends Entity> {
	value = input.required<string>();
	entityId = input.required<number>();
	propertyMetadata = input.required<PropertyToJson2>();

	@HostBinding('class.bg-red-100') @HostBinding('class.text-red-800') get created() {
		// return this.value === SaleOrderState.Created;
		return this.value() == 'Created' || this.value() == 'None';
	}

	@HostBinding('class.bg-orange-100') @HostBinding('class.text-orange-800') get inProgress() {
		// return this.value === SaleOrderState.InProgress;
		return this.value() == 'InProgress' || this.value() == 'Requested' || this.value() == 'Partially';
	}

	@HostBinding('class.bg-green-100') @HostBinding('class.text-green-800') get completed() {
		// return this.value === SaleOrderState.Completed;
		return this.value() == 'Completed' || this.value() == 'All';
	}

	@HostBinding('class.bg-slate-200') get canceled() {
		// return this.value === SaleOrderState.Completed;
		return this.value() == 'Canceled';
	}
}
