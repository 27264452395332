import { defineEntityGetAllProcedure, defineProcedure, defineRouter } from "@nest/common-rpc-shared";
import { entities } from "@nest/domain-entities-declarations";
import { InvoiceDto, OutboundDocumentDto, OutboundDocumentPriceTypeSummaryDto } from "@nest/domain-entities-metadata-dtos";
import { type } from "@nest/utils-shared";
import { z } from "zod";
import { OutboundDocumentCreateDtoSchema, OutboundDocumentUpdateDtoSchema } from "./zod";

export const outboundDocumentsRouter = defineRouter({
	outboundDocuments: {
		getAll: defineEntityGetAllProcedure(entities.outboundDocuments),
		search: defineProcedure({
			input: z.object({
				term: z.string(),
			}),
			output: type<OutboundDocumentDto[]>(),
		}),
		create: defineProcedure({
			input: OutboundDocumentCreateDtoSchema,
			output: type<OutboundDocumentDto>(),
		}),
		update: defineProcedure({
			input: OutboundDocumentUpdateDtoSchema,
			output: type<OutboundDocumentDto>(),
		}),
		printById: defineProcedure({
			input: z.object({
				id: z.number(),
				numberOfCopies: z.number().optional(),
				printerIds: z.array(z.number()).optional(),
			}),
			output: type<any>(),
		}),
		printByShipmentRouteId: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number(),
				numberOfCopies: z.number().optional(),
				printerIds: z.array(z.number()).optional(),
			}),
			output: type<Blob>(),
		}),
		sendOutboundDocument: defineProcedure({
			input: z.object({
				id: z.number(),
				recipientEmail: z.string(),
				subject: z.string(),
				message: z.string(),
			}),
			output: type<void>(),
		}),
		toCorrectionDocument: defineProcedure({
			input: z.object({
				originalId: z.number(),
			}),
			output: type<{
				originalDocument: OutboundDocumentDto;
				document: OutboundDocumentDto;
			}>(),
		}),
		generateInvoice: defineProcedure({
			input: z.object({
				id: z.number(),
			}),
			output: type<InvoiceDto>(),
		}),
		generateBatchInvoices: defineProcedure({
			input: z.object({
				ids: z.array(z.number()),
			}),
			output: type<InvoiceDto[]>(),
		}),
		sendToWms: defineProcedure({
			input: z.object({
				id: z.number(),
				skipWarnings: z.boolean().optional(),
			}),
			output: type<void>(),
		}),
		dispatch: defineProcedure({
			input: z.object({
				id: z.number(),
			}),
			output: type<void>(),
		}),
		cancel: defineProcedure({
			input: z.object({
				id: z.number(),
			}),
			output: type<void>(),
		}),
		printInvoice: defineProcedure({
			input: z.object({
				id: z.number(),
				numberOfCopies: z.number().optional(),
				printerIds: z.array(z.number()).optional(),
			}),
			output: type<any>(),
		}),
		printInvoiceByShipmentRouteId: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number(),
				numberOfCopies: z.number().optional(),
				printerIds: z.array(z.number()).optional(),
			}),
			output: type<any>(),
		}),
		schedulePrintInvoiceByShipmentRouteId: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number(),
				numberOfCopies: z.number().optional(),
				printerIds: z.array(z.number()).optional(),
			}),
			output: type<any>(),
		}),
		paymentTypeSummary: defineProcedure({
			input: z
				.object({})
				.catchall(z.any())
				.refine((obj) => Object.keys(obj).length > 0, {
					message: "Filter must have at least one key",
				}),
			output: type<OutboundDocumentPriceTypeSummaryDto>(),
		}),
		checkOutboundDocuments: defineProcedure({
			input: z.object({
				outboundDocumentIds: z.array(z.number()),
			}),
			output: type<void>(),
		}),
		setCanceledOnCheck: defineProcedure({
			input: z.object({
				outboundDocumentIds: z.array(z.number()),
			}),
			output: type<void>(),
		}),
	},
});
