import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@nest/common-core-frontend';
import { PartyEntity } from '@nest/domain-entities-declarations';
import type { PartyDto } from '@nest/domain-entities-metadata-dtos';
import { PartyAutocompleteDirective } from '@nest/domain-parties-frontend-core';
import { SelectComponent } from '@nest/uikit-select-ui';
import { ApplicationService } from '../../services/application.service';

@Component({
	templateUrl: './organization-layout.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet, ReactiveFormsModule, SelectComponent, PartyAutocompleteDirective]
})
export class OrganizationLayoutComponent {
	app = inject(ApplicationService);
	auth = inject(AuthService);

	organization = signal<PartyEntity | null>(null);
	loaded = signal(false);

	formControl = new FormControl<PartyDto>(null);

	constructor() {
		toObservable(this.app.organization)
			.pipe(takeUntilDestroyed())
			.subscribe((organization) => {
				this.loaded.set(true);
				this.organization.set(organization);
			});

		this.formControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((organization) => this.auth.setOrganizationId(organization?.id));
	}
}
