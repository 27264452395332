import { APP_INITIALIZER, inject } from '@angular/core';
import { ShipmentVehicleDto, ShipmentVehicleEntityName } from '@nest/domain-entities-metadata-dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit-entity-presentation-ui';
import { autoPathsFor } from '@nest/utils-shared';

const paths = autoPathsFor<ShipmentVehicleDto>();

export const ShipmentVehiclesDefaultElements = fieldsToElements(paths(['createdAt', 'name', 'shipmentMaximumCapacity']));

export function provideShipmentVehicleEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				const entityMetadata = inject(EntityMetadataService);

				return () => {
					entityMetadata.registerEntity({
						entityName: ShipmentVehicleEntityName,
						defaultElements: ShipmentVehiclesDefaultElements
					});
				};
			}
		}
	];
}
