<ngx-sonner-toaster richColors closeButton position="top-center" />

@if (serverInfo() && isTestMode()) {
	<div class="text-white z-50 text-center py-1.5 px-2 bg-red-800/60 uppercase text-xs tracking-wider w-50 absolute left-1/2 -translate-x-1/2 rounded-b-md">
		Testovací prostředí ({{ serverInfo()?.environment }})
	</div>
}

<!-- <div class="w-[400px] fixed top-0 right-0 h-screen bg-white shadow-md z-20">
	<div class="text-xl font-semibold p-4">Notifikace</div>

	<div class="flex flex-col gap-1">
		<div class="p-2 rounded-md m-2 shadow-sm border border-slate-200 h-32">
			<div class="text-slate-500">15:44</div>
			<div>Nová verze aplikace!</div>
		</div>
		<div class="p-2 rounded-md m-2 shadow-sm border border-slate-200 h-32">
			<div class="text-slate-500">15:44</div>
			<div>Nová verze aplikace!</div>
		</div>
	</div>
</div> -->

<div class="w-screen h-screen fixed top-0 left-0" libLayoutContainer>
	<router-outlet></router-outlet>
</div>
