import { defineEntityGetAllProcedure, defineProcedure, defineRouter } from '@nest/common-rpc-shared';
import { ItemWithPrice } from '@nest/domain-edi-sale-orders-shared';
import { DeliveryTypeToPaymentTypeDto, OutboundDocumentDto, SaleOrderDto } from '@nest/domain-entities-metadata-dtos';
import { entities } from '@nest/domain-entities-declarations';
import { type } from '@nest/utils-shared';
import { z } from 'zod';
import { GetItemPriceQuerySchema } from './models';
import { CreateSaleOrderDtoSchema, DoSendCustomerMessageDtoSchema, UpdateSaleOrderDtoSchema } from './zod';

export const saleOrderRouter = defineRouter({
	saleOrders: {
		getAll: defineEntityGetAllProcedure(entities.saleOrders),
		// getById: defineEntityGetByIdProcedure(entities.saleOrders),
		search: defineProcedure({
			input: z.object({
				term: z.string()
			}),
			output: type<SaleOrderDto[]>()
		}),
		getById: defineProcedure({
			input: z.object({
				id: z.number(),
				fields: z.array(z.string())
			}),
			output: type<SaleOrderDto>()
		}),
		create: defineProcedure({
			input: CreateSaleOrderDtoSchema,
			output: type<SaleOrderDto>()
		}),
		update: defineProcedure({
			input: UpdateSaleOrderDtoSchema,
			output: type<SaleOrderDto>()
		}),
		getItemPrice: defineProcedure({
			input: GetItemPriceQuerySchema,
			output: type<ItemWithPrice<any>>()
		}),
		cancelOrder: defineProcedure({
			input: z.number(),
			output: type<void>()
		}),
		getAsOutboundDocument: defineProcedure({
			input: z.object({
				saleOrderId: z.number()
			}),
			output: type<Partial<OutboundDocumentDto>>()
		}),
		doAutomat: defineProcedure({
			input: z.object({
				saleOrderId: z.number(),
				skipWarnings: z.boolean().default(false)
			}),
			output: type<void>()
		}),
		getDeliveryTypePaymentTypes: defineProcedure({
			input: z.object({
				type: z.string()
			}),
			output: type<DeliveryTypeToPaymentTypeDto[]>()
		}),
		getPredefinedTextCustomerMessage: defineProcedure({
			input: z.object({
				saleOrderId: z.number(),
				predefinedTextId: z.number()
			}),
			output: type<{ subject: string; content: string }>()
		}),
		doSendCustomerMessage: defineProcedure({
			input: DoSendCustomerMessageDtoSchema,
			output: type<void>()
		}),
		duplicate: defineProcedure({
			input: z.number(),
			output: type<SaleOrderDto>()
		})
	}
});
