import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SaleOrderDto } from '@nest/domain-entities-metadata-dtos';
import { Entity, EntityElement } from '@nest/uikit-entity-presentation-ui';
import { ICON_IMPORTS } from '@nest/uikit-icon-ui';
import { autoPathsFor } from '@nest/utils-shared';
import { provideIcons } from '@ng-icons/core';
import { iconoirCash } from '@ng-icons/iconoir';

export const SaleOrderBuyerWidgetName = 'SaleOrderBuyer';
export const SaleOrderBuyerWidgetFields = autoPathsFor<SaleOrderDto>()(['buyerSnapshot']);

@Component({
	standalone: true,
	templateUrl: './sale-order-buyer-widget.component.html',
	styles: `
		:host {
			@apply block p-2;
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ICON_IMPORTS],
	providers: [provideIcons({ iconoirCash })]
})
export class SaleOrderBuyerWidgetComponent<E extends Entity> {
	entityName = input.required<string>();
	element = input.required<EntityElement>();
	entity = input.required<Pick<SaleOrderDto, 'buyer' | 'currency'>>();
}
