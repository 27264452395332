import { SaleOrderLineSchema, SaleOrderSchema } from '@nest/domain-entities-metadata-shared-zod';
import { z } from 'zod';

export const DoSendCustomerMessageDtoSchema = z.object({
	saleOrderId: z.number(),
	recipientEmail: z.string(),
	subject: z.string(),
	content: z.string()
});

export type DoSendCustomerMessageDto = z.infer<typeof DoSendCustomerMessageDtoSchema>;

export const CreateSaleOrderLineDtoSchema = SaleOrderLineSchema.pick({
	code: true,
	text: true,
	item: true,
	quantity: true,
	price: true,
	warehouse: true,
	recipientPriceAmount: true,
	note: true
});

export const CreateSaleOrderDtoSchema = SaleOrderSchema.pick({
	salesChannel: true,
	documentType: true,
	deliveryType: true,
	paymentType: true,
	warehouse: true,
	carrier: true,
	buyer: true,
	recipientSnapshot: true,
	recipient: true,
	buyerNote: true,
	note: true,
	currency: true,
	exchangeRate: true,
	buyerOrderNumber: true,
	excludeFromStatistics: true,
	sepaMandateDateOfSignature: true,
	sepaMandateReference: true,
	foreignOrderNumber: true,
	requestDate: true,
	deliveryShipmentTimeWindows: true
}).extend({
	skipWarnings: z.coerce.boolean().optional(),
	reserve: z.coerce.boolean().default(false),
	lines: z.array(CreateSaleOrderLineDtoSchema)
});

export type CreateSaleOrderDto = z.infer<typeof CreateSaleOrderDtoSchema>;
export type CreateSaleOrderLineDto = z.infer<typeof CreateSaleOrderLineDtoSchema>;

export const UpdateSaleOrderDtoSchema = CreateSaleOrderDtoSchema.omit({
	salesChannel: true
}).extend({
	id: z.number(),
	lines: z.array(
		CreateSaleOrderLineDtoSchema.extend({
			id: z.number().nullish()
		})
	)
});

export type UpdateSaleOrderDto = z.infer<typeof UpdateSaleOrderDtoSchema>;

export const SaveSaleOrderOptionsDto = z.object({
	reserve: z.coerce.boolean().optional(),
	skipWarnings: z.coerce.boolean().optional()
});

export type SaveSaleOrderOptionsDto = z.infer<typeof SaveSaleOrderOptionsDto>;
