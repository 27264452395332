import { defineEntityGetAllProcedure, defineRouter, defineUploadProcedure } from '@nest/common-rpc-shared';
import { entities } from '@nest/domain-entities-declarations';
import { type } from '@nest/utils-shared';
import { z } from 'zod';

export const inventoryRouter = defineRouter({
	inventory: {
		items: {
			getAll: defineEntityGetAllProcedure(entities.items),
			doImport: defineUploadProcedure({
				input: z.object({
					importType: z.string()
				}),
				output: type<{ jobId: string }>(),
				files: {
					file: true
				}
			})
			// search: defineProcedure({
			// 	input: z.object({
			// 		term: z.string()
			// 	}),
			// 	output: type<{ items: ItemEntity[] }>()
			// })
		}
	}
});
