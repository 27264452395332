import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthService, injectRpcClient, TrackingService } from '@nest/common-core-frontend';
import { commonRouter } from '@nest/common-core-shared';
import { NotificationsStore } from '@nest/domain-activities-frontend-entity-presentation';
import { LAYOUT_CONTAINER_IMPORTS } from '@nest/uikit-layout-ui';
import { InferPromise } from '@nest/utils-shared';
import { NgxSonnerToaster } from 'ngx-sonner';
import { filter } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	providers: [],
	standalone: true,
	imports: [RouterOutlet, NgxSonnerToaster, LAYOUT_CONTAINER_IMPORTS],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: `
		:host {
			@apply overflow-auto h-screen block;
		}
	`
})
export class AppComponent {
	updates = inject(SwUpdate);
	router = inject(Router);
	tracking = inject(TrackingService);
	notifications = inject(NotificationsStore);
	client = injectRpcClient(commonRouter);
	auth = inject(AuthService);

	originalDocumentTitle = document.title;
	serverInfo = signal<InferPromise<ReturnType<typeof this.client.serverInfo>> | null>(null);
	isTestMode = computed(() => this.serverInfo()?.environment != 'production');

	constructor() {
		// this.notifications.refreshUnreadNotificationCount();
		// this.notifications.startCheckingNewNotifications(interval(15000).pipe(takeUntilDestroyed()));

		toObservable(this.auth.identity).subscribe(identity => {
			if (identity) {
				this.getServerInfo();
			}
		});

		effect(() => {
			if (this.serverInfo() && this.serverInfo().environment != 'production') {
				document.title = `[${this.serverInfo().environment}] ${this.originalDocumentTitle}`;
			}
		});

		this.updates.versionUpdates.subscribe(event => {
			if (event.type == 'VERSION_DETECTED') {
				if (confirm('Je k dispozici nová verze aplikace. Chcete obnovit aplikaci?')) {
					this.updates.activateUpdate().then(() => {
						window.location.reload();
					});
				}
			}
		});

		this.tracking.track('app_load');

		this.router.events.pipe(filter(x => x instanceof NavigationEnd)).subscribe(x =>
			this.tracking.track('navigation_end', {
				path: (x as NavigationEnd).url
			})
		);
	}

	getServerInfo() {
		if (this.serverInfo() == null) {
			this.client.serverInfo().then(info => {
				info.environment
				this.serverInfo.set(info);
			});
		}
	}
}
