<form [formGroup]="formGroup" class="flex flex-col gap-4 w-96">
	<img class="self-center w-20 mb-8" src="/svgs/logo-big.svg" />
	<div>
		<lib-textbox label="Uživatelské jméno" [formControl]="formGroup.controls.username"></lib-textbox>
	</div>
	<div>
		<lib-textbox label="Heslo" [formControl]="formGroup.controls.password" type="password"></lib-textbox>
	</div>
	<button nest (click)="onSubmit()" type="button" variant="primary">Přihlásit se</button>
</form>
