import { Injectable, inject } from "@angular/core";
import { AuthService, injectRpcClient } from "@nest/common-core-frontend";
import { partiesRouter } from "@nest/domain-parties-shared";
import { derivedAsync } from "ngxtension/derived-async";

@Injectable({
	providedIn: "root",
})
export class ApplicationService {
	auth = inject(AuthService);
	partiesClient = injectRpcClient(partiesRouter);

	organization = derivedAsync(() => {
		const organizationId = this.auth.organizationId();
		return organizationId ? this.partiesClient.parties.getById({ id: organizationId }) : null;
	});
}
