import { APP_INITIALIZER, inject } from '@angular/core';
import { TaxDto, TaxEntityName } from '@nest/domain-entities-metadata-dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit-entity-presentation-ui';
import { autoPathsFor } from '@nest/utils-shared';

export function provideTaxesEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);

				return () => {
					entityMetadata.registerEntity({ entityName: TaxEntityName, defaultElements: fieldsToElements(autoPathsFor<TaxDto>()(['code'])) });
				};
			}
		}
	];
}
