import { APP_INITIALIZER, inject } from '@angular/core';
import { Router } from '@angular/router';
import { injectRpcClient, SearchService } from '@nest/common-core-frontend';
import { TimeWindowPathRendererComponent } from '@nest/domain-common-frontend-entity-presentation';
import type { OutboundDocumentDto, OutboundDocumentLineDto } from '@nest/domain-entities-metadata-dtos';
import { OutboundDocumentEntityName, OutboundDocumentLineEntityName } from '@nest/domain-entities-metadata-dtos';
import { outboundDocumentsRouter } from '@nest/domain-outbound-documents-shared';
import { EntityDetailLinkPathRendererComponent, EntityMetadataService, EntityService, fieldsToElements } from '@nest/uikit-entity-presentation-ui';
import { autoPathsFor } from '@nest/utils-shared';
import { from } from 'rxjs';
import { OutboundDocumentStateColorEntityPathRendererComponent } from './outbound-document-state-color-path-render.component';

const paths = autoPathsFor<OutboundDocumentDto>();

export const OutboundDocumentDefaultElements = fieldsToElements(paths(['createdAt', 'warehouse.name', 'documentType.name', 'documentNumber', 'state', 'buyer.name']));
export const OutboundDocumentLineDefaultElements = fieldsToElements(autoPathsFor<OutboundDocumentLineDto>()(['item.code', 'item.name', 'quantity', 'price.netto']));

export function provideOutboundDocumentsEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				const entities = inject(EntityService);
				const client = injectRpcClient(outboundDocumentsRouter);
				const searchService = inject(SearchService);
				const router = inject(Router);

				return () => {
					entities.registerEntity({
						label: 'Výdejové doklady',
						key: 'outbound-documents',
						entityName: OutboundDocumentEntityName,
						previewLabelFn: item => `${item.documentNumber} (${item.foreignOrderNumber})`,
						linkFn: id => (id ? ['/outbound/documents', 'detail', id] : ['/outbound/documents']),
						searchFn: term => from(client.outboundDocuments.search({ term }))
					});

					entityMetadata.registerEntity({
						entityName: OutboundDocumentEntityName,
						defaultElements: OutboundDocumentDefaultElements
					});

					entityMetadata.registerEntity({
						entityName: OutboundDocumentLineEntityName,
						defaultElements: OutboundDocumentLineDefaultElements
					});

					entityMetadata.registerEntityPathRenderer(OutboundDocumentEntityName, paths(['state']), OutboundDocumentStateColorEntityPathRendererComponent);
					entityMetadata.registerEntityPathRenderer(OutboundDocumentEntityName, paths(['deliveryShipmentTimeWindows']), TimeWindowPathRendererComponent);

					entityMetadata.registerEntityPathRenderer(OutboundDocumentEntityName, paths(['documentNumber', 'foreignOrderNumber']), EntityDetailLinkPathRendererComponent);
				};
			}
		}
	];
}
