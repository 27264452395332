import { APP_INITIALIZER, inject } from '@angular/core';
// import { DocumentLineTemplateDataAccessService } from '@nest/domain/documents/templates/frontend/data-access';
import { EntityMetadataService } from '@nest/uikit-entity-presentation-ui';

export function provideTemplatesEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				// let documentLineTemplateDataAccess = inject(DocumentLineTemplateDataAccessService);

				return () => {
					// entityMetadata.registerEntity({
					// 	entityName: DocumentLineTemplateEntityName,
					// 	dataAccess: documentLineTemplateDataAccess,
					// 	defaultElements: fieldsToElements(autoPathsFor<DocumentLineTemplateDto>()(['id']))
					// });
				};
			}
		}
	];
}
