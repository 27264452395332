import { APP_INITIALIZER, inject } from '@angular/core';
import {
	InboundDeliveryOrderDocumentTypeDto,
	InboundDeliveryOrderDocumentTypeEntityName,
	InboundDeliveryOrderDto,
	InboundDeliveryOrderEntityName,
	InboundDeliveryOrderLineDto,
	InboundDeliveryOrderLineEntityName,
	InboundQueueDto,
	InboundQueueEntityName
} from '@nest/domain-entities-metadata-dtos';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit-entity-presentation-ui';
import { autoPathsFor } from '@nest/utils-shared';

export function provideInboundEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);

				return () => {
					entityMetadata.registerEntity({
						entityName: InboundDeliveryOrderDocumentTypeEntityName,
						defaultElements: fieldsToElements(autoPathsFor<InboundDeliveryOrderDocumentTypeDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: InboundDeliveryOrderLineEntityName,
						defaultElements: fieldsToElements(autoPathsFor<InboundDeliveryOrderLineDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: InboundDeliveryOrderEntityName,
						defaultElements: fieldsToElements(autoPathsFor<InboundDeliveryOrderDto>()(['id']))
					});
					entityMetadata.registerEntity({
						entityName: InboundQueueEntityName,
						defaultElements: fieldsToElements(autoPathsFor<InboundQueueDto>()(['id']))
					});
				};
			}
		}
	];
}
